import { FC } from 'react'
import { ShopifyProduct } from '@types'
import Image from 'next/image'
import Link from 'next/link'
import { formatPrice } from '@lib/pricing'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

interface ProductCardProps {
  product: ShopifyProduct
}

const ProductCard: FC<ProductCardProps> = ({ product }) => {
  const { locale } = useRouter()
  const { t } = useTranslation(['common'])

  const img = product.images.edges[0].node

  const productLink: Function = (): string => {
    return `/products/${product.handle}`
  }

  return (
    <article>
      <Link href={productLink()} passHref>
        <a className="relative block h-96 rounded-md overflow-hidden bg-slate-100 shadow-sm hover:shadow-lg transition-shadow">
          <Image
            src={img.url}
            layout="fill"
            alt={img.altText || product.title}
            objectFit="cover"
          />
        </a>
      </Link>
      <h3 className="mt-5">
        <Link href={productLink()}>
          <a>{product.title}</a>
        </Link>
      </h3>
      <p className="text-xl">
        <strong>
          {t(
            `common:price.${product.priceRange.minVariantPrice.currencyCode.toLowerCase()}`,
            {
              price: formatPrice(
                locale || 'en-US',
                parseInt(product.priceRange.minVariantPrice.amount, 10)
              ),
            }
          )}
        </strong>
      </p>
    </article>
  )
}

export default ProductCard
