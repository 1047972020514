import { FC } from 'react'
import type { ShopifyProduct } from '@types'
import cm from 'classnames'
import Link from 'next/link'

import { Button } from '@components/ui'

import { ProductCard } from '@components/common'
import { useTranslation, Trans } from 'next-i18next'

interface ProductsListingProps {
  className?: string
  title?: string
  products: Array<ShopifyProduct>
  showAll?: boolean
  wide?: boolean
  withInfo?: boolean
}

const ProductsListing: FC<ProductsListingProps> = ({
  title,
  products,
  showAll = false,
  wide = false,
  withInfo = false,
  ...rest
}) => {
  const { t } = useTranslation(['products', 'common'])

  return (
    <div {...rest}>
      {!!title && (
        <h2 className="text-4xl sm:text-5xl md:text-6xl tracking-wide text-center mb-8">{title}</h2>
      )}
      {withInfo && (
        <p className="mb-5">
          <Trans i18nKey="products:listingInfo" count={products.length}>
            <strong>{{count: products.length}}</strong>
          </Trans>
        </p>
      )}
      <div className={cm('grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3', wide ? 'xl:grid-cols-4' : 'lg:grid-cols-3')}>
        {products.map((product: ShopifyProduct) => (
          <ProductCard product={product} key={product.id} />
        ))}
      </div>
      {showAll && (
        <div className="text-center mt-16">
          <Link href="/products" passHref>
            <Button Component="a">{t('common:labels.showAllProducts')}</Button>
          </Link>
        </div>
      )}
    </div>
  )
}

export default ProductsListing
