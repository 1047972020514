import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { getCollections, getProductsInCollection } from "@lib/shopify"
import { Layout, ProductsListing } from '@components/common'
import type { GetStaticPropsContext, InferGetStaticPropsType } from 'next'
import { Categories, Hero } from '@components/home';
import { useTranslation } from 'next-i18next';
import Head from 'next/head';
import { ShopifyProduct } from '@types';
import { Container } from '@components/ui';

export async function getStaticProps({
  locale,
}: GetStaticPropsContext) {
  const products = await getProductsInCollection('frontpage', locale || 'en-US', 4)
  const collections = await getCollections(locale || 'en-US')

  return {
    props: {
      ...(await serverSideTranslations(locale || 'en-US', ['common', 'home', 'cart'])),
      products: products.map((p: { node: ShopifyProduct}) => p.node),
      collections
    }
  }
}

export default function Home({
  products,
  collections
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const { t } = useTranslation('common')

  return (
    <>
      <Head>
        <title>{t('common:metaTitles.home')}</title>
        <meta name="description" content={t('common:metaDescriptions.products')} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={t('common:metaTitles.home')} />
        <meta property="og:image" content="https://ennsens.com/assets/meta/og-image.jpg" />
        <meta property="og:description" content={t('common:metaDescriptions.products')} />
        <meta property="og:image:alt" content={t('common:slogan')} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={t('common:metaTitles.home')} />
        <meta name="twitter:description" content={t('common:metaDescriptions.products')} />
        <meta name="twitter:image" content="https://ennsens.com/assets/meta/og-image.jpg" />
        <meta name="twitter:image:alt" content={t('common:slogan')} />
      </Head>
      <Hero />
      <Categories categories={collections} className="my-20 md:my-32 lg:my-52" />
      <Container>
        <ProductsListing className="my-20 md:my-32 lg:my-52" title={t('labels.favoriteProducts')} products={products} showAll wide />
      </Container>
    </>
  )
}


Home.Layout = Layout
