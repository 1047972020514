import { FC } from 'react'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { Container } from '@components/ui'

import { Button } from '@components/ui'
import s from './Hero.module.css'

const Hero: FC = ({}) => {
  const { t } = useTranslation(['home', 'common'])

  return (
    <Container>
      <div className="text-center">
        <h1 className={s.title}>{t('title')}</h1>
        <Link href="/products" passHref>
          <Button Component="a">{t('common:labels.showAllProducts')}</Button>
        </Link>
      </div>
    </Container>
  )
}

export default Hero
