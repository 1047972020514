import { FC } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'

import { Container } from '@components/ui'

import s from './Categories.module.css'
import { ShopifyCollection } from '@types'

interface CategoriesProps {
  className?: string
  categories: ShopifyCollection[]
}

const Categories: FC<CategoriesProps> = ({ categories, ...rest }) => {
  const { t } = useTranslation(['home', 'common'])

  return (
    <section {...rest}>
      <Container>
        <h2 className="text-4xl mb-5 sm:text-5xl md:text-6xl tracking-wide">
          {t('common:labels.categories')}
        </h2>
        {categories.map((c) => (
          <div className={s.category} key={c.handle}>
            <Link href={`/products${c.path.value}`}>
              <a className={s.imgLink}>
                <Image
                  src={c.image.url}
                  alt={c.image.altText || c.title}
                  layout="fill"
                  objectFit="cover"
                />
              </a>
            </Link>
            <h3 className="text-2xl sm:text-4xl">{c.title}</h3>
            <Link href={`/products${c.path.value}`}>
              <a className={s.categoryLink}>
                {t('common:labels.showAllProducts')}
              </a>
            </Link>
          </div>
        ))}
      </Container>
    </section>
  )
}

export default Categories
